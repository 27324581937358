<template>
  <mi-dialog
    :model-value="modelValue"
    class="user-management-edit-user-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-none text-uppercase"> User Details </h5>

    <!-- Username -->
    <mi-text-field
      disable
      label="Username"
      :placeholder="user.username"
      outlined
    >
    </mi-text-field>

    <!-- Role -->
    <mi-select
      v-model="userRoleDescription"
      class="user-management-edit-user-dialog__role q-mt-md"
      :display-value="userRoleDescription || 'Select Role'"
      :options="roles.map(role => role.description)"
      label="Role"
      outlined
    >
    </mi-select>

    <!-- Action buttons -->
    <template #actions>
      <mi-btn flat @click="emitUpdateModelValue"> Cancel </mi-btn>
      <mi-btn :loading="isUserSaveInProgress" @click="triggerUserSave"> Save </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  import { editUserRole, getUsersRoles } from '@/api'
  import notify from '@/utils/notify'

  export default {
    name: 'UserManagementEditUserDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      user: {
        type: Object,
        required: true
      }
    },
    emits: ['update:model-value', 'save:user'],
    data() {
      return {
        isUserSaveInProgress: false,
        roles: [],
        userRoleDescription: '',
        selectedRoleObject: {}
      }
    },
    watch: {
      modelValue(current) {
        this.userRoleDescription = current ? this.user.role.description : ''
      }
    },
    async mounted() {
      this.roles = await getUsersRoles()
    },
    methods: {
      async triggerUserSave() {
        this.isUserSaveInProgress = true
        this.selectedRoleObject = this.roles.find(role => role.description === this.userRoleDescription)

        try {
          await editUserRole({ id: this.user.id, role: this.selectedRoleObject })

          this.$emit('save:user')
          this.emitUpdateModelValue()

          notify({
            title: 'Saved',
            content: `User "${ this.user.username || '' }" was successfully saved`,
            type: 'positive'
          })
        }
        finally {
          this.isUserSaveInProgress = false
        }
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      }
    }
  }
</script>

<style lang="scss">
  .user-management-edit-user-dialog .q-dialog__inner > .mi-card {
    width: 400px;
    max-width: 400px;
    min-width: 400px;
  }

  .user-management-edit-user-dialog__role {
    margin-bottom: 240px;
  }
</style>
