<template>
  <mi-dialog
    :model-value="modelValue"
    class="user-management-delete-user-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-sm q-mb-md"> Are you sure you want to delete this user? </h5>

    <!-- Subtitle -->
    <p class="q-mb-xl"> By deleting this user, this action cannot be undone. </p>

    <!-- Action buttons -->
    <template #actions>
      <mi-btn flat @click="emitUpdateModelValue"> Cancel </mi-btn>
      <mi-btn :loading="isUserDeleteInProgress" @click="triggerUserDelete"> Delete </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  import { deleteUser } from '@/api'
  import notify from '@/utils/notify'

  export default {
    name: 'UserManagementDeleteUserDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      user: {
        type: Object,
        required: true
      }
    },
    emits: ['update:model-value', 'delete:user'],
    data: () => ({
      isUserDeleteInProgress: false
    }),
    methods: {
      async triggerUserDelete() {
        this.isUserDeleteInProgress = true

        try {
          await deleteUser(this.user.id)

          this.emitUpdateModelValue()
          this.$emit('delete:user')

          notify({
            title: 'Deleted',
            content: `User "${ this.user.username || '' }" was successfully deleted`,
            type: 'positive'
          })
        }
        finally {
          this.isUserDeleteInProgress = false
        }
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      }
    }
  }
</script>

<style lang="scss">
  .user-management-delete-user-dialog .q-dialog__inner > .mi-card {
    width: 600px;
    max-width: 600px;
    min-width: 600px;
  }
</style>
